import Vue from 'vue';
import Router from 'vue-router';

import Home from '../components/Home.vue';
import Info from '../components/Info.vue';

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [
        { 
            path: '/', 
            component: Home, 
            meta: { authorize: [] } 
        },
        { 
            path: '/info', 
            name: 'Info',
            component: Info, 
            meta: { authorize: [] } 
        },
        // otherwise redirect to home
        { path: '*', redirect: '/' }
    ]
});

export default router
