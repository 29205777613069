<template>
<div></div>
</template>

<script>
export default {
	name: "Requests",
	components: {},
	methods: {

		fetchInfo: function(batch_number, oef_id) {
			//{"action":"tiledaterangewmsinfo", "properties":{"tile":"35TLF","date_start":"2020-10-05 00:00:00", "date_end":"2020-11-05 00:00:00"}}
			let postParams = {};

			postParams["action"] = "getInfo";
			postParams["properties"] = {}
			postParams["properties"]["batch_number"] = batch_number;
			postParams["properties"]["oef_id"] = oef_id;
			return this.$http.post(this.$store.state.serviceEndPoint, postParams);
		},
		fetchAll: function() {
			//{"action":"tiledaterangewmsinfo", "properties":{"tile":"35TLF","date_start":"2020-10-05 00:00:00", "date_end":"2020-11-05 00:00:00"}}
			let postParams = {};

			postParams["action"] = "getAll";
			return this.$http.post(this.$store.state.serviceEndPoint, postParams);
		},
		fetchOEF: function(oef_id) {
			//{"action":"tiledaterangewmsinfo", "properties":{"tile":"35TLF","date_start":"2020-10-05 00:00:00", "date_end":"2020-11-05 00:00:00"}}
			let postParams = {};

			postParams["action"] = "getOEF";
			postParams["properties"] = {}
			postParams["properties"]["oef_id"] = oef_id;
			return this.$http.post(this.$store.state.serviceEndPoint, postParams);
		},
		fetchBatch: function(batch_id) {
			//{"action":"tiledaterangewmsinfo", "properties":{"tile":"35TLF","date_start":"2020-10-05 00:00:00", "date_end":"2020-11-05 00:00:00"}}
			let postParams = {};

			postParams["action"] = "getBatch";
			postParams["properties"] = {}
			postParams["properties"]["batch_id"] = batch_id;
			return this.$http.post(this.$store.state.serviceEndPoint, postParams);
		},
		fetchLandCodes: function(batch_id) {
			//{"action":"tiledaterangewmsinfo", "properties":{"tile":"35TLF","date_start":"2020-10-05 00:00:00", "date_end":"2020-11-05 00:00:00"}}
			let postParams = {};

			postParams["action"] = "getLandCodes";
			postParams["properties"] = {}
			postParams["properties"]["batch_id"] = batch_id;
			return this.$http.post(this.$store.state.serviceEndPoint, postParams);
		}
	}
}
</script>