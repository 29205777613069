import 'bootstrap/dist/css/bootstrap.css';
//import '@fortawesome/fonteawesome-free/css/all.css';
//import '@fortawesome/fonteawesome-free/js/all.js';

import Vue from 'vue';
import Vuex from 'vuex';
import VueResource from 'vue-resource';
import App from './App.vue';
import { BootstrapVue , BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/css/main.css'
import Vuelidate from 'vuelidate';
import VueHorizontal from "vue-horizontal";
import CountryFlag from 'vue-country-flag';
import VueSocialSharing from 'vue-social-sharing';

Vue.use(VueSocialSharing);

Vue.component('country-flag', CountryFlag)

Vue.component(VueHorizontal)

Vue.use(Vuelidate);

Vue.config.productionTip = false;

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(Vuex);
Vue.use(VueResource);

Vue.config.productionTip = false;

const store = new Vuex.Store({
	state: {
        // serviceEndPoint:"http://localhost/endpoint.php"
		serviceEndPoint:"https://olivetrace.oliveoil-po.com/endpoint.php"
	}
	
});

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
});

  