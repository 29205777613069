<template>
<div>
  <div>
    <b-container class="bv-example-row">
  
    <b-card>
          <img src="../../public/bannerOlive2.png" width="100%" style="margin-right:15px !important">
      <b-card-text>
	
		
								<div itemprop="articleBody" style="text-align: left; margin-top: 50px;">
		<h2 style="text-align:center; line-height:30px;">Producer organization/Οργάνωση παραγωγών</h2>
<p style="text-align:center;">
{{this.org}}
</p>
<br/>
<h2 style="text-align:center; line-height:30px;">TRACEABLE OLIVE PRODUCT/ΙΧΝΗΛΑΣΙΜΟ ΕΛΑΙΟΚΟΜΙΚΟ ΠΡΟΪΟΝ</h2>
<p style="font-weight:bold; margin-top:30px; font-size:16px;">
Batch number/Αριθμός παρτίδας : {{this.batchNumber}}</p>
<h5 style="line-height:30px; font-weight:bold; margin-top: 30px;">
Origin/Προέλευση</h5>
<p>
Area/Περιοχή : {{this.area}} </p>
<p> Province/Νομός : {{this.province}}  </p>
<p> Location/Τοποθεσία : {{this.location}}
</p></div>
<div style="margin-top: 50px; margin-bottom: 50px;" class="table-responsive">
 <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>Harvest date/Ημερομηνία συγκομιδής</th>
                    <th>Producer name/Όνομα παραγωγού</th>
                    <th>Kg olives collected/Kg συλλεχθέντος ελαιοκάρπου</th>
                    <th>Batch/Παρτίδα</th>
                    <th>Land code/Κωδικός αγροτεμαχίου</th>
                    <th>Variety/Ποικιλία</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{this.harvest_date}}</td>
                    <td>{{this.producer_name}}</td>
                    <td>{{this.kg_olives_collected}}</td>
                    <td><div v-for="batch in batches" :key="batch.batch_id">{{batch.Batch}}</div></td>
                    <td><div v-for="code in land_codes" :key="code.batch_id">{{code.Land_Code}}</div></td>
                    <td>{{this.variety}}</td>
                </tr>
            </tbody>
        </table>
        </div>
        <div itemprop="articleBody" style="text-align: left;">
   <h5 style="line-height:30px; font-weight:bold;"> Product details/Πληροφορίες προϊόντος</h5>
<p>Production date/Ημερομηνία παραγωγής : {{this.production_date}}</p>
<p>Produced in/Παράγεται στο : {{this.produced_in}}</p>
<p>Bottling date/Ημερομηνία εμφιάλωσης : {{this.bottling_date}}</p>
<p>Bottled by/Εμφιαλώνεται από : {{this.bottled_by}}</p>
<p>Bottled in/Εμφιαλώνεται στο : {{this.bottled_in}}</p>

<h5 style="line-height:30px; font-weight:bold; margin-top: 30px;">Certification/Πιστοποίηση</h5>
<p>Certification company/Πιστοποιητικός φορέας : {{this.certification_company}}</p>
<p>Standard/Πρότυπο : {{this.iso}}</p>

  </div>
 </b-card-text>

      <template #footer>
        <footer class="footer" role="contentinfo">
		<div class="container" style="display: flex;">
			
			<p style="position: absolute;right: 30px;" class="pull-right">
				<a href="#top" id="back-top">
					Back to Top				</a>
			</p>
			<p>
				&copy; 2022 Lot			</p>
		</div>
	</footer>
      </template>
    </b-card>
</b-container>

</div>
  
  <Requests ref="requests" />	
  </div>
</template>

<script>
import Requests from './Requests.vue';
export default {
  name: 'Info',
  components: {
		Requests: Requests
	},
  data() {
    return {
            oef: '',
            kwdikos: '',
            loading: false,
            oefName: '',
            batchNumber: '',
            params: null,
            org: '',
            location: '',
            area: '',
            province: '',
            harvest_date: '',
            producer_name: '',
            kg_olives_collected: '',
            variety: '',
            batch_id: '',
            produced_in: '',
            production_date: '',
            bottling_date: '',
            bottled_by: '',
            bottled_in: '',
            certification_company: '',
            iso: '',
            oef_id: '',
            batches: [],
            land_codes: [],
            details: []
        };
  },
  props: {
    msg: String
  }
  ,methods: {
    init: function() {
      this.batchNumber = this.$route.query.batch;
      this.oef_id = this.$route.query.oef_id;
       this.$refs.requests.fetchInfo(this.batchNumber, this.oef_id).then((response) => {
              this.org = response.data[0].Producer_org;
              this.location = response.data[0].Location;
              this.area = response.data[0].Area;
              this.province = response.data[0].Province;
              this.harvest_date = response.data[0].Harvest_Date;
              this.producer_name = response.data[0].Producer_Name;
              this.kg_olives_collected = response.data[0].Kg_olives_collected;
              this.variety = response.data[0].Variety;
              this.batch_id = response.data[0].Batch_ID;
              this.produced_in = response.data[0].Produced_in;
              this.production_date = response.data[0].Production_Date;
              this.bottling_date = response.data[0].Bottling_Date;
              this.bottled_by = response.data[0].Bottled_by;
              this.bottled_in = response.data[0].Bottled_in;
              this.certification_company = response.data[0].Certification_Company;
              this.iso = response.data[0].Standard;
              this.$refs.requests.fetchBatch(this.batch_id).then((response) => {
                this.batches = response.data;
              })
              this.$refs.requests.fetchLandCodes(this.batch_id).then((response) => {
                this.land_codes = response.data;
              })
       })
       
    }
  }
  ,mounted:function() {
		this.init();}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
