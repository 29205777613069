<template>
<div>
  <div>
    <b-container class="bv-example-row">
  
    <b-card title="SEARCH BATCH CODE / ΑΝΑΖΗΤΗΣΗ ΚΩΔΙΚΟΥ ΠΑΡΤΙΔΑΣ">
          <img src="../../public/bannerOlive2.png" width="100%" style="margin-right:15px !important">
      <b-card-text>
	
		
								<div itemprop="articleBody" style="margin-top: 50px;">
		<h2 style="text-align:center; line-height:30px;">The traceability of olive products<br/> From the farm to fork with the common Italian-greek traceability system</h2>
<p>
For almost a decade, the Italian National Olive Growers Consortium has been cooperating with olive growers’ cooperatives in Greece, both on islands and on mainland, in a joint traceability project for olive oil and table olives, aiming at product safety and transparency in the food chain.</p>
<p>
The system that was developed and perfected over time, allows consumers to trace the origin of the product through the batch code on the package (bottles and cans of different types). This system is certified according to the ISO 22005:2007 standard.</p>
<p>
If you would like to know the origin of the product and get more information about it, select the cooperative you are interested in and enter the batch code in the relevant field.</p>
<br/>
<h2 style="text-align:center; line-height:30px;">Ιχνηλασιμότητα ελαιοκομικών προϊόντων<br/> 
  Από το αγρόκτημα μέχρι το πιάτο, με το ενιαίο Ιταλικό-Ελληνικό σύστημα ιχνηλασιμότητας</h2>
<p>
Για σχεδόν μια δεκαετία, η Ιταλική Εθνική Κοινοπραξία Ελαιοκαλλιεργητών συνεργάζεται με συνεταιρισμούς ελαιοκαλλιεργητών στην Ελλάδα, τόσο στη νησιωτική όσο και στην ηπειρωτική χώρα, σε ένα κοινό πρόγραμμα ιχνηλασιμότητας για το ελαιόλαδο και τις επιτραπέζιες ελιές, με στόχο την ασφάλεια των προϊόντων και τη διαφάνεια στην εφοδιαστική αλυσίδα.
</p>
<p>
Το σύστημα που αναπτύχθηκε και τελειοποιήθηκε με την πάροδο του χρόνου, επιτρέπει στους καταναλωτές να εντοπίζουν την προέλευση του προϊόντος μέσω του κωδικού παρτίδας που βρίσκεται στη συσκευασία (μπουκάλια και μεταλλικά δοχεία διαφορετικού τύπου). Το σύστημα αυτό είναι πιστοποιημένο σύμφωνα με το πρότυπο ISO 22005:2007.</p>
<p>
Εάν επιθυμείτε να γνωρίζετε την προέλευση του προϊόντος και να λάβετε περισσότερες πληροφορίες σχετικά με αυτό, επιλέξτε το συνεταιρισμό που σας ενδιαφέρει και πληκτρολογήστε τον κωδικό παρτίδας στο σχετικό πεδίο.</p></div>
 <b-row style="margin-top:50px; margin-bottom: 50px;;">
    <b-col sm="3">
    </b-col>
    <b-col sm="6" style="text-align: center;">
      <label style="font-weight: bold;">Επιλογή ΟΕΦ:</label>
      <b-form-select v-model="oef" :options="chooseOEF"></b-form-select>
      <b-button @click="oefSubmit" style="margin-top: 10px;" variant="primary">
      <span style="margin-right:5px;" class="spinner-border spinner-border-sm" v-show="loading"></span>
      <span>ΕΠΙΒΕΒΑΙΩΣΗ</span></b-button>
    </b-col>
     <b-col sm="3">
    </b-col>
  </b-row>
 </b-card-text>
 <div v-if="this.oefName">
  <h5>{{this.oefName}}</h5>
 <b-row style="margin-top:50px; margin-bottom: 50px;;">

 <b-col sm="4">
    </b-col>
    <b-col sm="4" >
      <label style="font-weight: bold;">ΚΩΔΙΚΟΣ ΙΧΝΗΛΑΣΙΜΟΤΗΤΑΣ:</label>
      <b-form-input v-model="batchNumber" name="batchNumber" ></b-form-input>
      <b-button @click="infoSubmit" style="margin-top: 10px;" variant="primary">
      <span style="margin-right:5px;" class="spinner-border spinner-border-sm" v-show="loading"></span>
      <span>ΕΠΙΒΕΒΑΙΩΣΗ</span></b-button>
    </b-col>
    <b-col sm="4">
    </b-col>
    </b-row>
 </div>
   <table width="100%">
  <tbody>
    <tr>
      <td>
        <img alt="logoIta" src="../../public/flag_ita.png" style="width: 60px; height: 60px; float:left;" align="center"/>
        <a href="http://epignosys2.developing.it/Tfo_Epignosys/OilTraceITAGR.aspx" target="_blank" style="text-align:center;float:left;font-size:14px;"><br/>Visit CNO's traceability system</a>	
      </td>
    </tr>    
  </tbody>  
</table>
<img alt="logoIta" src="../../public/Picture4.png" style="margin-top:50px; text-align: center; object-fit: contain;
    width: 100%;
    height: 100%;"/>
      <template #footer>
        <footer class="footer" role="contentinfo">
		<div class="container" style="display: flex;">
			
			<p style="position: absolute;right: 30px;" class="pull-right">
				<a href="#top" id="back-top">
					Back to Top				</a>
			</p>
			<p>
				&copy; 2022 Lot			</p>
		</div>
	</footer>
      </template>
    </b-card>
</b-container>

</div>
  
  <Requests ref="requests" />	
  </div>
</template>

<script>
import Requests from './Requests.vue';
import {router} from '../router/index'
export default {
  name: 'Home',
  components: {
		Requests: Requests
	},
  data() {
    return {
            oef: '',
            kwdikos: '',
            loading: false,
            oefName: '',
            batchNumber: '',
            allOEF: [],
            chooseOEF: [],
            oef_id: ''
        };
  },
  props: {
    msg: String
  }
  ,methods: {
    init: function() {
      this.$refs.requests.fetchAll().then((response) => {
        this.chooseOEF = [];
              this.allOEF = response.data;
              this.allOEF.forEach(element => {
                this.chooseOEF.push(element.Epwnymia);
              });
            })
    },
    oefSubmit () {
            this.loading = true;
            this.oefName = null;
            this.$refs.requests.fetchOEF(this.oef).then((response) => {
              this.loading = false;
              this.oefName = response.data[0].Epwnymia;
              this.oef_id = response.data[0].ID;
            })
        },
    infoSubmit () {
            this.loading = true;
            router.push({
              name: 'Info',
              query: { batch: this.batchNumber , oef_id: this.oef_id }
            });
        },  
  }
  ,mounted:function() {
		this.init();}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

p {
  text-align: left;
}
</style>
